import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Users from "../pages/Authentication/Users";
import Roles from "../pages/Authentication/Role";
import Permisstions from "../pages/Authentication/RoleAction";
import CheckRank from "../pages/CheckRank";
import ListSite from "../pages/ListSite";
import ViewDetail from "../pages/ListSite/ViewDetail";

const authProtectedRoutes = [
  { path: "/check-rank", component: CheckRank },
  { path: "/view-detail/:id", component: ViewDetail },
  { path: "/listsite", component: ListSite },
  { path: "/users", component: Users },
  { path: "/roles", component: Roles },
  { path: "/permisstions", component: Permisstions },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
