import moment  from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  Table,
} from "antd";
import { useParams } from 'react-router-dom';
import { getDetailSite } from '../../helpers/helper';

function ViewDetail() {
    const [data, setData] = useState([])

    const { id } = useParams();
    useEffect(() => {
        if(!id) return;
        getDetailSite(id)
        .then(res => {
            setData(res.data.dataCheck)
        })
    },[id])

    const columns = [
        {
          title: "Ngày kiểm tra",
          render:(_, record) => {
            return <span>{moment(record.createdAt).format('L')}</span>
          }
        },
        {
            title: "Desktop",
            dataIndex: "desktop",
            render:(_, record) => {
              return <span>{record.desktop? record.desktop: "N/A"}</span>
            }
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            render:(_, record) => {
              return <span>{record.mobile? record.mobile: "N/A"}</span>
            }
        },
      ];
  return (
    <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Chi tiết rank site" pageTitle="Chi tiết rank site" />
        <div>
            <Table columns={columns} dataSource={data} />
        </div>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default ViewDetail
