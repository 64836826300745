import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  message,
  Input,
  Button,
  Form,
  Space,
  Tooltip,
  Table,
  Select,
  Spin,
} from "antd";
import "./style.css";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  checkRank,
  getBalance,
} from "../../helpers/helper";
import moment from "moment";
import { Drawer } from "antd";
import toSlug from "../../common/function";
import axios from "axios";

const { Option } = Select;
const listDevice = [
  {
    id: 1,
    name: "desktop"
  },
  {
    id: 2,
    name: "mobile"
  }
]
const Categories = () => {
  document.title = "Management Categories";

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [balance, setBalance] = useState(0)
  
  const onFinish = async (data) => {
    if(loading) return;
    setLoading(true)
    const res = await checkRank(`?keyword=${data.keyword}&url=${data.url}&device=${data.device}`);
    setData(res.data)
    setLoading(false)
  }
  useEffect(async () => {
    const res =  await getBalance()
    setBalance(res?.data?.credit)
  },[])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Kiểm tra rank site" pageTitle="Kiểm tra rank site" />
          <h2>Số lần kiểm tra còn lại: <strong>{balance}</strong></h2>
          {Object.keys(data).length >0 
            &&
            <>
                <div><strong>Xếp hạng</strong>: <strong style={{color: "red", fontSize: 20}}>{data.pos? data.pos :"Không có"}</strong> </div>
                <div><strong>Keyword</strong>: {data.keyword} </div>
                <div><strong>Url</strong>: {form.getFieldValue("url")}</div>
                <div><strong>Thiết bị</strong>: {form.getFieldValue("device")} </div>
            </>
          }
      
          <Row>
            <Col xs={12}>
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
              >
                <Form.Item
                    name="url"
                    label="URL (bỏ https, http) vd: ko66.com"
                    rules={[
                      {
                        required: true,
                        message: "Please input url",
                      },
                      {
                        type: "string",
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter url name"
                      name="url"
                    />
                </Form.Item>

                <Form.Item
                    name="keyword"
                    label="Keyword"
                    rules={[
                      {
                        required: true,
                        message: "Please input keyword",
                      },
                      {
                        type: "string",
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter keyword"
                      name="keyword"
                    />
                </Form.Item>

                <Form.Item
                    name="device"
                    label="device"
                    rules={[
                      {
                        required: true,
                        message: "Please input device",
                      },
                    ]}
                  >
                    <Select
                        placeholder="Select a device!"
                        allowClear
                        showSearch
                        name="device"
                      >
                        {listDevice.length > 0 &&
                          listDevice.map((item) => {
                            return (
                              <Option key={item.id} value={item.name}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                </Form.Item>
                  <Form.Item className="mt-3">
                    <Space>
                      <Button type="primary" htmlType="submit">
                        {loading? <Spin /> : "Kiểm tra"}
                      </Button>
                    </Space>
                  </Form.Item>
              </Form>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Categories;