//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

//login
export const API_USER_LOGIN = "/api/user/login";

//Tags
export const API_TAG_INSERT = "/api/tag/insert";
export const API_TAG_GET_PAGING = "/api/tag/getPaging";
export const API_TAG_UPDATE = "/api/tag/update";
export const API_TAG_DELETE = "/api/tag/delete";

//Role
export const API_ROLE_INSERT = "/api/role/insert";
export const API_ROLE_UPDATE = "/api/role/update";
export const API_ROLE_DELETE = "/api/role/delete";
export const API_ROLE_GETALL = "/api/role/getAll";
export const API_ROLE_GET_PAGING = "/api/role/getPaging";
export const API_ROLE_GET_PAGING_BY_ID = "/api/role/getById";

//Action
export const API_ACTION_INSERT = "/api/action/insert";
export const API_ACTION_UPDATE = "/api/action/update";
export const API_ACTION_DELETE = "/api/action/delete";
export const API_ACTION_GETALL = "/api/action/getAll";
export const API_ACTION_GET_PAGING = "/api/action/getPaging";
export const API_ACTION_GET_PAGING_BY_ID = "/api/action/getById";

//RoleAction
export const API_ROLEACTION_INSERT = "/api/roleaction/insert";
export const API_ROLEACTION_UPDATE = "/api/roleaction/update";
export const API_ROLEACTION_INSERTMANY = "/api/roleaction/insertMany";
export const API_ROLEACTION_UPDATEMANY = "/api/roleaction/updateMany";

export const API_ROLEACTION_DELETE = "/api/roleaction/delete";
export const API_ROLEACTION_GET_PAGING = "/api/roleaction/getPaging";
export const API_ROLEACTION_GET_PAGING_BY_ID = "/api/roleaction/getById";

//User
export const API_USER_INSERT = "/api/user/insert";
export const API_USER_UPDATE = "/api/user/update";
export const API_USER_DELETE = "/api/user/delete";
export const API_USER_GET_PAGING = "/api/user/getPaging";
export const API_USER_GET_PAGING_BY_ID = "/api/user/getById";

// Check rank

export const API_CHECK_RANK = "/api/web/check-rank";
export const API_GET_BALANCE = "/api/web/get-balance";
export const API_SITE_INSERT = "/api/web/create-site";
export const API_SITE_DELETE = "/api/web/delete-site";
export const API_GET_ALL_SITE = "/api/web/get-all";
export const API_GET_DETAIL_SITE = "/api/web";